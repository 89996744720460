import { render, staticRenderFns } from "./photoTemplate-edit.vue?vue&type=template&id=62968629&scoped=true&"
import script from "./photoTemplate-edit.vue?vue&type=script&lang=js&"
export * from "./photoTemplate-edit.vue?vue&type=script&lang=js&"
import style0 from "./photoTemplate-edit.vue?vue&type=style&index=0&id=62968629&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62968629",
  null
  
)

export default component.exports